import { useForm } from "../hooks/useForm";
import Loader from "./Loader";
import Message from "./Message";

const initialForm = {
  name: "",
  edad: "",
  pais: "",
  email: "",
  behave: "",
  comments: "",
};
const validationForm = (form) => {
  let errors = {};
  let regexName = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
  let regexEdad = /^[0-9\b]+$/;
  let regexpais = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜü\s]+$/;
  let regexEmail = /^(\w+[/./-]?){1,}@[a-z]+[/.]\w{2,}$/;
  let regexBehave = /^.{10,3000}$/;
  let regexComments = /^.{10,3000}$/;

  if (!form.name.trim()) {
    errors.name = "What is your name again?";
  } else if (!regexName.test(form.name.trim())) {
    errors.name = "Your 'name' needs letters";
  }
  if (!form.edad.trim()) {
    errors.edad = "How old are you again?";
  } else if (!regexEdad.test(form.edad.trim())) {
    errors.edad = "Your 'age' is a number";
  }

  if (!form.pais.trim()) {
    errors.pais = "In which city you live?";
  } else if (!regexpais.test(form.pais.trim())) {
    errors.pais = "Your city's name has letters";
  }
  if (!form.email.trim()) {
    errors.email = "I need an 'email' to reach you for questions";
  } else if (!regexEmail.test(form.email.trim())) {
    errors.email = "Your email needs this symbol @";
  }
  if (!form.behave.trim()) {
    errors.behave = "How did you behave?";
  } else if (!regexBehave.test(form.behave.trim())) {
    errors.behave =
      "Explain your behave. It cannot be smaller than 300 characters or longer than 3000 characters";
  }
  if (!form.comments.trim()) {
    errors.comments = "What do you wish?";
  } else if (!regexComments.test(form.comments.trim())) {
    errors.comments = "Your letter cannot be longer than 3000 characters";
  }

  return errors;
};

const ContactForm = () => {
  const {
    form,
    errors,
    loading,
    response,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useForm(initialForm, validationForm);

  return (
    <div className="FormContainer">
      <h1 className="titleForm">Write your wishes to Santa</h1>
      <div>
        <form className="form-elements" onSubmit={handleSubmit}>
          <div>
            <label className="question">What is your name?</label>
            <br />
            <input
              type="text"
              name="name"
              placeholder="Write here your name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={form.name}
              required
            />
          </div>
          {errors.name && <p className="error">{errors.name}</p>}
          <div>
            <label className="question">How old are you?</label>
            <br />
            <input
              type="number"
              name="edad"
              placeholder="Age"
              onBlur={handleBlur}
              onChange={handleChange}
              value={form.edad}
              required
            />
          </div>
          {errors.edad && <p className="error">{errors.edad}</p>}
          <div>
            <label className="question">In which city do you live?</label>
            <br />
            <input
              type="text"
              name="pais"
              placeholder="City"
              onBlur={handleBlur}
              onChange={handleChange}
              value={form.pais}
              required
            />
          </div>
          {errors.pais && <p className="error">{errors.pais}</p>}
          <div>
            <label className="question">Your email address</label>
            <br />
            <input
              type="email"
              name="email"
              placeholder="Email"
              onBlur={handleBlur}
              onChange={handleChange}
              value={form.email}
              required
            />
          </div>
          {errors.email && <p className="error">{errors.email}</p>}
          <div>
            <label className="question">How have you been behaving?</label>
            <br />
            <textarea
              name="behave"
              cols="50"
              rows="10"
              placeholder="This year I have been..."
              onBlur={handleBlur}
              onChange={handleChange}
              value={form.behave}
              required
            ></textarea>
          </div>
          {errors.behave && <p className="error">{errors.behave}</p>}
          <div>
            <label className="question">
              What would you want as your wish?
            </label>
            <br />
            <textarea
              name="comments"
              cols="50"
              rows="30"
              placeholder="Dear Santa..."
              onBlur={handleBlur}
              onChange={handleChange}
              value={form.comments}
              required
            ></textarea>
          </div>
          {errors.comments && <p className="error">{errors.comments}</p>}
          <div>
            <input
              className="button"
              type="submit"
              value="Send to Santa"
              required
            />
          </div>
        </form>
      </div>
      {loading && <Loader />}
      {response && (
        <Message msg="Your mail has been sent to Santa" bgColor="#green" />
      )}
    </div>
  );
};

export default ContactForm;
