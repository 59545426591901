import React, { useState, useEffect } from "react";

const Timer = () => {
  const getCountdown = () => {
    // const year = new Date().getFullYear() + 1;
    const timeRemaining = new Date(`2023-12-25`) - new Date();
    let countdown = {};
    if (timeRemaining > 0) {
      countdown = {
        Days: Math.floor(timeRemaining / (1000 * 60 * 60 * 24)),
        Hours: Math.floor((timeRemaining / (1000 * 60 * 60)) % 24),
        Minutes: Math.floor((timeRemaining / 1000 / 60) % 60),
        Seconds: Math.floor((timeRemaining / 1000) % 60),
      };
    }
    return countdown;
  };
  const [countdown, setCountdown] = useState(getCountdown());

  useEffect(() => {
    setTimeout(() => {
      setCountdown(getCountdown());
    }, 1000);
  });

  const data = [];
  Object.entries(countdown).forEach(([unit, value]) => {
    data.push(
      <div key={Math.random().toString(16)}>
        <div>
          <strong>{value}</strong>
        </div>{" "}
        <div>{unit}</div>
      </div>
    );
  });

  return (
    <div className="container-timer">
      <h3 className="title-timer">Christmas Day</h3>
      <div className="data-timer">{data}</div>
    </div>
  );
};
export default Timer;
