import React from "react";
import Feliz from "./Feliz";
import Logo from "./Logo";
import Timer from "./Timer";

const Header = () => {
  return (
    <div className="Container-Header">
      <Logo />
      <Timer />
      <Feliz />
    </div>
  );
};

export default Header;
