import React from "react";

const Logo = () => {
  return (
    <div className="container-logo">
      <h2 className="title-logo">
        DearSanta<span>.NZ</span>
      </h2>
    </div>
  );
};
export default Logo;
