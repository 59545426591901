import "./App.css";
import ContactForm from "./components/ContactForm";
import Header from "./components/Header";
import PopupCookies from "./components/PopupCookies";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Header />
      </header>
      <div className="Form">
        <ContactForm className="elementForm " />
      </div>
      <div>
        <PopupCookies />
      </div>
      <div className="footter"></div>
    </div>
  );
}

export default App;
