import CookieConsent from "react-cookie-consent";

import "../PopupCookies.css";

const PopupCookies = () => {
  return (
    <div className="popupCookies">
      <CookieConsent
        disableStyles
        location="none"
        buttonText="ACCEPT"
        cookieName="DearSanta.nz"
        overlay
        overlayClasses="overlayclass"
      >
        This website is a project in test mode. By clicking the "ACCEPT" button
        you understand this, and discharge us from any liability. If you do not
        agree, leave the web now.{" "}
        <span style={{ fontSize: "10px" }}>
          We collect personal information from you, including information about
          your: name contact information We collect your personal information in
          order to: you will receive an email with the letter that the child
          wrote to Santa Besides our staff, we share this information with:
          formspree.io in order to process the data from our form, and to send
          you an email. Providing some information is optional. If you choose
          not to enter your email, we'll be unable to send you an email with the
          letter that the child wrote to Santa. We keep your information safe by
          storing in formspree.io's files . We keep your information for 90 days
          at which point we securely destroy it by . You have the right to ask
          for a copy of any personal information we hold about you, and to ask
          for it to be corrected if you think it is wrong. If you’d like to ask
          for a copy of your information, or to have it corrected, please
          contact us at hello@DearSanta.NZ.
        </span>
      </CookieConsent>
    </div>
  );
};

export default PopupCookies;
