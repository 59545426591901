import React from "react";

const Feliz = () => {
  return (
    <div className="Container-Feliz">
      <p>Merry Christmas</p>
      <p>Feliz Navidad</p>
      <p>Joyeux Noël</p>
      <p>메리 크리스마스</p>
      <p>Meri Kirihimete</p>
      <p>Manuia le Kirisimasi</p>
      <p>Maligayang Pasko</p>
      <p>聖誕節快樂</p>
      <p>Frohe Weihnachten</p>
      <p>メリークリスマス</p>
    </div>
  );
};

export default Feliz;
